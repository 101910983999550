import React from "react";
import Theme from "./Theme";
import { navLinks } from "../database.js";

const AsideLeft: React.FC = () => {
  return (
    <div className="aside-left">
      <Theme />
      <div className="aside-svgs">
        {navLinks.map(({ url, comp, id, title }) => (
          <div key={id}>
            <a href={url} className="aside-svg">
              <img src={comp} />
            </a>
            <p>{title}</p>
          </div>
        ))}
      </div>
      <div className="aside-bottom">
        <a
          href="https://www.linkedin.com/in/monint/"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className="fab fa-linkedin-in fa-lg"></i>
        </a>
        <a
          href="https://github.com/monint578"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className="fab fa-github fa-lg"></i>
        </a>
        <a
          href="https://codepen.io/monint578"
          rel="noopener noreferrer"
          target="_blank"
        >
          <i className="fab fa-codepen fa-lg"></i>
        </a>
      </div>
    </div>
  );
};

export default AsideLeft;
