import React, { useEffect } from "react";
import HeroSection from "./Content/HeroSection";
import About from "./Content/About";
import Skills from "./Content/Skills";
import Projects from "./Content/Projects";
import Experience from "./Content/Experience";
import Contact from "./Content/Contact";
import Footer from "./Content/Footer";
import AOS from "aos";

const Container: React.FC = () => {
  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);
  return (
    <div className="container">
      <HeroSection />
      <About />
      <Skills />
      <Experience />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
};

export default Container;
