import React, { useState } from "react";

const navLinks = [
  { url: "/#about", name: "About Me", id: 1 },
  { url: "/#skills", name: "Skill Set", id: 2 },
  { url: "/#experience", name: "Experience", id: 3 },
  { url: "/#projects", name: "Projects", id: 4 },
  { url: "/#contacts", name: "Contacts", id: 5 },
];

const SideNav: React.FC = () => {
  const [style, setStyle] = useState<string>("nav-side");
  const [animation, setAnimation] = useState<string>("");
  const [menuStatus, setMenuStatus] = useState<boolean>(true);

  const handleClick = () => {
    switch (menuStatus) {
      case true:
        setMenuStatus(false);
        setStyle("nav-side nav-active");
        setAnimation("burger-open");
        document.documentElement.style.setProperty("--overflow", "hidden");
        break;
      case false:
        setMenuStatus(true);
        setStyle("nav-side");
        setAnimation("burger-close");
        document.documentElement.style.setProperty("--overflow", "auto");
        break;
      default:
      // do nothing
    }
  };

  return (
    <nav>
      <div className="nav-link-side">
        <button onClick={handleClick} className={animation}>
          <div className="bar1"></div>
          <div className="bar2"></div>
          <div className="bar3"></div>
        </button>
      </div>

      <div className={style}>
        <ul>
          {navLinks.map(({ url, name, id }) => (
            <li key={id}>
              <a href={url} className="underline-nav" onClick={handleClick}>
                <span className="font-code">0{id} </span>
                {name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default SideNav;
