import React from "react";

const HeroSection: React.FC = () => {
  return (
    <header>
      <p className="font-code">Hi, my name is</p>
      <h1 className="header-title underline">Monika Intaitė.</h1>
      <h1 className="header-descr">I build things for the web.</h1>
      <p className="header-about">
        I‘m a front-end web developer from Kaunas currently working with
        ReactJS.
      </p>
      <span className="btn btn-border font-code">
        <a
          className="btn-background"
          href="mailto:monika.intaite@gmail.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Get in Touch
        </a>
      </span>
    </header>
  );
};

export default HeroSection;
