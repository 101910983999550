import React from "react";
import "aos/dist/aos.css";

const udemySrc1 = "https://www.udemy.com/course/the-web-developer-bootcamp/";
const courseraSrc1 =
  "https://www.coursera.org/account/accomplishments/verify/P2WHEBLZFM7Q?utm_source=ln&utm_medium=certificate&utm_content=cert_image&utm_campaign=sharing_cta&utm_product=course";
const courseraSrc2 = "https://www.coursera.org/learn/responsivedesign";
const courseraSrc3 =
  "https://www.coursera.org/professional-certificates/google-ux-design?skipBrowseRedirect=true";

const Skills: React.FC = () => {
  const udemy = require("../../assets/images/udemy.png");

  return (
    <section id="skills">
      <h2 className="underline" data-aos="fade-right">
        <span className="font-code">02.</span> Skill Set
      </h2>
      <div className="cards-grid-2">
        <article className="card" data-aos="fade-left">
          <div className="card-title">
            <h4>My main skills are:</h4>
          </div>
          <p className="font-code">
            HTML & CSS / JavaScript / React / TypeScript / Material-UI /
            Bootstrap / Github / Figma / jQuery
          </p>
        </article>
        <article className="card" data-aos="fade-right">
          <div className="card-title">
            <h4>I have also used:</h4>
          </div>
          <p className="font-code">
            SASS / Wordpress / Node.js / React Native / Tailwindcss / GraphQL /
            Redux{" "}
          </p>
        </article>
      </div>
      <div className="cards-grid-2">
        <article className="card" data-aos="fade-right">
          <div className="card-title">
            <h4>The Web Developer Bootcamp</h4>
            <div className="text-right">
              <a href={udemy} rel="noopener noreferrer" target="_blank">
                <i className="fas fa-external-link-alt"></i>
              </a>
            </div>
          </div>
          <p>
            This was my introduction to understanding how websites work and how
            to create them. During this bootcamp on{" "}
            <a
              href={udemySrc1}
              className="color-primary"
              rel="noopener noreferrer"
              target="_blank"
            >
              Udemy
            </a>
            , I've learned about JavaScript, jQuery, Node.js, Express, MongoDB,
            Git, Bootstrap v3.
          </p>
        </article>
        <article className="card" data-aos="fade-left">
          <div className="card-title">
            <h4>Advanced Styling with Responsive Design</h4>
            <div className="text-right">
              <a href={courseraSrc1} rel="noopener noreferrer" target="_blank">
                <i className="fas fa-external-link-alt"></i>
              </a>
            </div>
          </div>
          <p>
            I've decided to focus further on UI/UX and learn more about
            responsive design. This{" "}
            <a
              href={courseraSrc2}
              className="color-primary"
              rel="noopener noreferrer"
              target="_blank"
            >
              Coursera
            </a>{" "}
            course helped me to understand how capable CSS is in creating
            intuitive user experiences.
          </p>
        </article>
      </div>
      <article className="card skills-container" data-aos="fade-left">
        <div className="card-title">
          <h4>Google UX Design Professional Certificate</h4>
          <div className="text-right">
            <p className="font-code">In progess</p>
          </div>
        </div>
        <p>
          In my current job, in addition to front-end development, I fill the UX
          designer position. In order to be better at my job, I've started to
          reach for{" "}
          <a
            href={courseraSrc3}
            className="color-primary"
            rel="noopener noreferrer"
            target="_blank"
          >
            Google Professional Certificate
          </a>
          . This certificate consists of 7 courses and I've already finished
          two.
        </p>
      </article>
    </section>
  );
};

export default Skills;
