import ToraberuImg from "./assets/images/toraberu.png";
import Profile1Img from "./assets/images/profile1.png";
import Profile2Img from "./assets/images/profile2.png";
import NativeImg from "./assets/images/reactnative.png";
import SearchImg from "./assets/images/search.png";
import AboutSvg from "./assets/icons/user.svg";
import SkillsSvg from "./assets/icons/skills.svg";
import ExperienceSvg from "./assets/icons/marker.svg";
import ProjectsSvg from "./assets/icons/projects.svg";
import ContactsSvg from "./assets/icons/email.svg";

//   Is used in Theme.js
export const themes = [
  {
    name: "Default",
    bg: "#f4f9fc",
    heading: "#0f1b61",
    accent: "#73bbc5",
    text: "#0f1b61",
    primary: "#7f00e0",
  },
  {
    name: "Electric dark",
    bg: "#0A192F",
    heading: "#E9EEFD",
    accent: "#c54ca3",
    text: "#CCD6F6",
    primary: "#64FFDA",
  },
  {
    name: "Cherry on top",
    bg: "#F8E5E5",
    heading: "#000000",
    accent: "#77D1F8",
    text: "#C68A8E",
    primary: "#FA255E",
  },
  {
    name: "Watermelon",
    bg: "#EFEFE8",
    heading: "#000000",
    accent: "#6DAC4F",
    text: "#0A5E2A",
    primary: "#FE0000",
  },
  {
    name: "Summer Vibes",
    bg: "#FFF685",
    heading: "#FF1D58",
    accent: "#00DDFF",
    text: "#F75990",
    primary: "#0049B7",
  },
  {
    name: "Asphalt",
    bg: "#444444",
    heading: "#FFFFFF",
    accent: "#FA255E",
    text: "#D8D8D8",
    primary: "#08FDD8",
  },
  {
    name: "Blue",
    bg: "#f9fdff",
    heading: "#286fb4",
    accent: "#b0dde4",
    text: "#286fb4",
    primary: "#df4c73",
  },
  {
    name: "Earth tones",
    bg: "#ece8d7",
    heading: "#222",
    accent: "#e49c4b",
    text: "#302718",
    primary: "#638844",
  },
  {
    name: "RGB",
    bg: "#000",
    heading: "#fff",
    accent: "#0000ff",
    text: "#ff0000",
    primary: "#00ff00",
  },
  {
    name: "LTU",
    bg: "#fff",
    heading: "#006a44",
    accent: "#fdb913",
    text: "#000",
    primary: "#c1272d",
  },
  {
    name: "Galaxy",
    bg: "#00076f",
    heading: "#f4f9fc",
    accent: "#f5bf0b",
    text: "#c9ccfd",
    primary: "#f91ada",
  },
];

// Is used in About.js
export const aboutMe = [
  {
    name: "Planner",
    description:
      "I love planning even the smallest things, as I think it‘s the most productive way to achieve my goals. I even created a step-by-step plan for this very website!",
  },
  {
    name: "Design at Heart",
    description:
      "My favorite thing to do is create something new from scratch. I use Figma for creating website designs. Also in my free time, I'm making jewelry, design, and sew clothes.",
  },
  {
    name: "Team Player",
    description:
      "I understand that one player can't win the game. Communicating and sharing ideas is very important for taking projects to the next level. Thats why I love working in a team environment.",
  },
];

export const navLinks = [
  { url: "/#about", comp: AboutSvg, title: "About me", id: 1 },
  { url: "/#skills", comp: SkillsSvg, title: "Skill Set", id: 2 },
  {
    url: "/#experience",
    comp: ExperienceSvg,
    title: "Experience",
    id: 3,
  },
  { url: "/#projects", comp: ProjectsSvg, title: "Projects", id: 4 },
  { url: "/#contacts", comp: ContactsSvg, title: "Contacts", id: 5 },
];

// Is used in Projects.js
export const projects = [
  {
    name: "Search Splash",
    gitlink: "https://github.com/monint578/search-splash",
    link: "https://monint578.github.io/search-splash/",
    codelink: "#",
    gitIconStyle: "fab fa-github",
    linkIconStyle: "fas fa-external-link-alt",
    codeIconStyle: "none",
    prog: "React.js / Redux / SASS",
    img: SearchImg,
    descr:
      "The app fetches images from the Unsplash API and displays them in a masonry grid. I have used Redux to save search queries which are easily accessible in the future.",
  },
  {
    name: "Toraberu",
    gitlink: "https://github.com/monint578/toraberu",
    link: "https://toraberu.herokuapp.com/",
    codelink: "#",
    gitIconStyle: "fab fa-github",
    linkIconStyle: "fas fa-external-link-alt",
    codeIconStyle: "none",
    prog: "Node.js / Express / MongoDB / Boostrap 4 / Docker",
    img: ToraberuImg,
    descr:
      "This is my personal project for sharing my most beloved places that I have visited. I wanted to challenge myself by designing a unique user interface. This project was created by remaking the YelpCamp project from the Udemy course: The Web Developer Bootcamp.",
  },
  {
    name: "Profile page v1",
    gitlink: "https://github.com/monint578/monint-profile",
    link: "https://v1.intaite.com",
    codelink: "#",
    gitIconStyle: "fab fa-github",
    linkIconStyle: "fas fa-external-link-alt",
    codeIconStyle: "none",
    prog: "React.js / Figma",
    img: Profile1Img,
    descr:
      "I wanted to learn React.js so I used it to create my first profile page. This was a single page application with several routes. It was  a fun experience but I knew I can make something better!",
  },
  {
    name: "Profile page v2",
    gitlink: "https://github.com/monint578/monint-profile-v2",
    link: "#",
    codelink: "#",
    gitIconStyle: "fab fa-github",
    linkIconStyle: "none",
    codeIconStyle: "none",
    prog: "React.js / Figma",
    img: Profile2Img,
    descr:
      "This is the page you are currently viewing! I wanted to create it with minimalist web design and add advanced CSS. I always wanted to make a color-changing application, so I did!",
  },
  {
    name: "React Native",
    gitlink: "https://github.com/monint578/ReactSeals-Developer-Assignment",
    link: "#",
    codelink: "#",
    gitIconStyle: "fab fa-github",
    linkIconStyle: "none",
    codeIconStyle: "none",
    prog: "React Native / Android Studio",
    img: NativeImg,
    descr:
      "This is my first project built with React Native. This app is made to fetch data from themoviedb.org and display it in the user interface. The main page is for browsing: a preview of three movie lists sorted by different categories. The second page shows a single movie's details.",
  },
];

export const sideExperience = [
  {
    name: "Promotional Representative",
    where: 'UAB "Partizanas"',
    when: "2018 07 - 2019 11",
  },
  {
    name: "Sales Manager",
    where: 'UAB "Garlita" knitting manufacturer',
    when: "2019 05 - 07",
  },
  {
    name: "Deputy to sewing manager",
    where: 'UAB "Garlita" knitting manufacturer',
    when: "2019 04 - 05",
  },
  {
    name: "Intern",
    where: 'UAB "Garlita" knitting manufacturer',
    when: "2019 02 - 04",
  },
  {
    name: "Administrator of the weaving manufacturing",
    where: 'UAB "Liningas" weaving manufacturer',
    when: "2017 06 - 07",
  },
  {
    name: "Knitting manufacturing assistance",
    where: 'UAB "Omniteksas" knitting manufacturer',
    when: "2016 07 - 08",
  },
];
