import React from "react";
import "aos/dist/aos.css";

import { projects } from "../../database";

const Projects: React.FC = () => {
  return (
    <section id="projects">
      <h2 className="underline" data-aos="fade-right">
        <span className="font-code">03.</span> Things I've Built
      </h2>
      {projects.map(
        ({
          name,
          gitlink,
          link,
          codelink,
          gitIconStyle,
          linkIconStyle,
          codeIconStyle,
          prog,
          img,
          descr,
        }) => (
          <article
            data-aos="fade-right"
            className="cards-grid-projec"
            key={name}
          >
            <div className="card-projects">
              <div className="card-title">
                <h3>{name}</h3>
                <div className="card-links">
                  <a href={gitlink} rel="noopener noreferrer" target="_blank">
                    <i className={gitIconStyle}></i>
                  </a>
                  <a href={link} rel="noopener noreferrer" target="_blank">
                    <i className={linkIconStyle}></i>
                  </a>
                  <a href={codelink} rel="noopener noreferrer" target="_blank">
                    <i className={codeIconStyle}></i>
                  </a>
                </div>
              </div>
              <p className="card-descr">{descr}</p>
              <p className="font-code">{prog}</p>
            </div>
            <div className="card-img">
              <img src={img} alt={name} />
            </div>
          </article>
        )
      )}
    </section>
  );
};

export default Projects;
