import React from "react";
import "aos/dist/aos.css";

import { sideExperience } from "../../database";

const Experience: React.FC = () => {
  return (
    <div id="experience">
      <h2 className="underline" data-aos="fade-right">
        <span className="font-code">04.</span> Work Experience
      </h2>
      <div className="card" style={{ display: "none" }}></div>
      <div className="card-experience-grid card" data-aos="fade-right">
        <div className="card-experience-title">
          <h4 className="bold">Front-end developer and UX designer</h4>
          <p className="font-code">UAB "Bright Projects"</p>
          <p className="sm">2021 08 - present</p>
        </div>
        <article className="card-experience-body">
          <p>
            Currently, I’m working on the “Robotikos Akademija” project. My
            day-to-day tasks consist of creating UX wireframes for new features,
            solving bugs, maintaining a public marketplace and admin panel.
            Also, I've started working on a new project by building an entire UX
            wireframe for the system by using Figma.
          </p>
        </article>
      </div>
      <div className="card-experience-grid card" data-aos="fade-left">
        <div>
          <div className="card-experience-title">
            <h4 className="card-title">Junior ReactJS developer</h4>
            <p className="font-code">UAB "Mediapark"</p>
            <p className="sm">2020 11 - 2021 08</p>
          </div>
          <div className="card-experience-title">
            <h4 className="bold">ReactJS Intern</h4>
            <p className="font-code">UAB "Mediapark"</p>
            <p className="sm">2020 10 - 2020 11</p>
          </div>
        </div>
        <article className="card-experience-body">
          <p>
            In Mediapark I was working on several projects where my main
            responsibilities include building WEB applications using created
            designs, implementing APIs using React, Typescript, and Material UI
            design. Also, I’ve collaborated in creating internal forms and
            tables library by extending the Material-UI package. This library
            later was used in multiple different projects.
          </p>
        </article>
      </div>
      <article className="card card-experience-grid-2" data-aos="fade-right">
        {sideExperience.map(({ name, where, when }) => (
          <div className="card-experience-title" key={name}>
            <p className="bold header-descr">{name}</p>
            <p className="font-code">{where}</p>
            <p className="sm">{when}</p>
          </div>
        ))}
      </article>
    </div>
  );
};

export default Experience;
