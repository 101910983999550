import React from "react";

const AsideRight: React.FC = () => {
  return (
    <div className="aside-right">
      <p className="font-code">monika.intaite@gmai.com</p>
    </div>
  );
};

export default AsideRight;
