import React from "react";
import "aos/dist/aos.css";

import { aboutMe } from "../../database";

const About: React.FC = () => {
  const picture = require("../../assets/images/picture.png");

  return (
    <section id="about">
      <h2 className="underline" data-aos="fade-right">
        <span className="font-code">01.</span> About me
      </h2>
      <article data-aos="fade-left">
        <div className="about-grid-2">
          <p>
            I've started my career in the textile manufacturing sector but soon
            I realized that I wanted to learn and grow more. Such opportunities
            in this sector were limited. So I shifted my focus to programming
            for the web. I‘ve started learning the basics of web development on
            various course platforms. And after a year of learning I was hired
            as a ReactJS developer and I've been working since.
          </p>
          <div className="pic">
            {" "}
            <img src={picture} alt="" />
          </div>
        </div>
        <div className="about-grid-3" data-aos="fade-right">
          {aboutMe.map(({ name, description }) => (
            <div className="about-card" key={name} data-aos="fade-right">
              <p className="font-code">{name}</p>
              <p className="sml">{description}</p>
            </div>
          ))}
        </div>
      </article>
    </section>
  );
};

export default About;
